import { SnackbarOrigin } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CustomSnackbarProps {
  open: boolean;
  severity: "success" | "error" | "warning" | "info";
  variant?: "filled" | "outlined" | "standard";
  anchorOrigin?: SnackbarOrigin;
  message: string;
  duration?: number;
}

const initialState: CustomSnackbarProps = {
  open: false,
  message: "",
  severity: "success",
  variant: "filled",
};

export const NotifyReducer = createSlice({
  name: "notify",
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<CustomSnackbarProps>) =>
      action.payload,
  },
});

export const { setAlert } = NotifyReducer.actions;
export default NotifyReducer.reducer;
